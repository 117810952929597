div.myCart{
    width: 100%;
    height: 100%;
}

div.myCart h1{
    font-size: 24px;
    margin-top: 8px;
    font-family: 'Montserrat', sans-serif;
}
div.myCart p{
    font-size: 14px;
    max-width: 400px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
}
.myCart__cart{
    margin-top: 20px;
}
div.myCart input{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
}
.cart--action{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}
.cart--action h4{
    text-align: center;
    font-size: 24px;
    color:#21214e;
    font-weight: 600;
}
.cart--action .actions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}
.cart--action .actions a{
    display: block;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: all 0.5s;
}
.cart--action .actions a:hover{
    background:#ccc;
}
.cart--action .actions a:active{
    border:2px solid #000;
    background:#8f8e8e;

}

@media screen and (max-width:900px){
    .allProductInCart{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    .myCart__cart{
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        width: 280px;
        padding: 0;
        margin: 0;
    }
    div.myCart p {
        display: none;
    }
    .myCart__cart .cart--image{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .myCart__cart .cart--image img{
        width: 100%;
        height: 100px;
        object-fit: cover;
    }
    .myCart__cart .cart--action{
        width: 100%;
    }
}
@media screen and (max-width:500px){
    div.myCart__general h1{
        font-size: 20px;
    }
    div.myCart__general h4{
        font-size: 14px;
    }
    div.myCart__general h5{
        font-size: 14px;
    }
    div.myCart h1{
        font-size: 20px;
    }
    .cart--action h4{
        font-size: 16px;
    }
}