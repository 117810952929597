:root {
  --primary-text-color: #000;
  --primary-text-hover-color: rgb(0, 123, 255);
  --secondary-text-color: #fff;
  --primary-background-color: #fff;
  --primary-input-color: #e6e7e5;
  --third-text-color: #555;
}

.HeaderComponent {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

}

header {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

header .search-input {
  width: 100%;
  max-width: 400px;
  background-color: var(--primary-input-color);
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  display: flex;
  margin-right: 20px;
}

.search-responsive-input {
  width: 240px;
  display: none;
  padding: 3px 8px;
  background-color: rgb(228, 223, 223);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.search-respon-input {
  padding: 3px 8px;
  background-color: rgb(228, 223, 223);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  display: none;
}

header .search-respon-input:hover {
  background: #a7a6a6;
}

header .search-input input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text-color);
  background-color: transparent;
  border: none;
}

.search-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

header .items .select-language {
  position: relative;
  padding: 3px 20px;
  border-radius: 16px;
  border: none;
  position: relative;
  background-color: rgb(228, 223, 223);
  padding: 3px 20px;
  border-radius: 16px;
  border: none;
}

header .items .select-language .main-language {
  display: flex;
  gap: 15px;
}

header .items .select-language .main-language,
span {
  color: var(--secondary-text-color);
  color: black;
  padding: 5px 12px;
}

header .items .select-language .dropdown {
  transition: 0.3s ease;
  width: 100%;
  position: absolute;
  top: 40px;
  right: 0;
  display: none;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  z-index: 100;
  border: none;
  border-radius: 16px;
  overflow: hidden;
}

header .items .select-language .dropdown span {
  text-align: center;
  color: white;
  border-bottom: 1px solid white;
  background-color: rgb(151, 149, 149);
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.navbar__active--class{
  color: rgb(0, 153, 255) !important;
  font-weight: 600;
  border-bottom:1px solid rgb(0, 153, 255);
}
header .items .select-language .dropdown span:hover {
  background: rgb(105, 104, 104);
}

header .items .select-language .dropdown .rus {
  border-bottom: none;
}

header .items .select-language.active .dropdown {
  display: flex;
}

.dark-mode {
  width: 60px;
  padding: 15px 0;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

.account-dark-mode {
  display: flex;
  align-items: center;
  gap: 20px;
}



.nav-bar .fa-bars {
  font-size: 24px;
  margin-left: 20px;
  margin-bottom: 16px;
  padding: 4px 0;
  cursor: pointer;
  display: none;
}
.marginBottom{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.account-dark-mode .current{
  position: relative;
  padding:2px 8px;
}

.account-dark-mode .bi-cart4{
  cursor: pointer;
}

.account-dark-mode .currentCartNumber{
  position: absolute;
  display: flex;
  align-items:center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 16px;
  border-radius:10px;
  font-size: 14px;
  background:red;
  color:white;
  top: 0;
  right: 0;
}

.nav-bar .fa-remove {
  font-size: 24px;
  margin-left: 20px;
  padding: 4px 0;
  cursor: pointer;
  display: none;
}

.dark-mode.toggled {
  background-color: #000;
  border:1px solid #ccc;
}

.toggle-knob {
  width: 26px;
  height: 26px;
  background-color: #f5a623;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.3s, background-color 0.3s;
}

.dark-mode.toggled .toggle-knob {
  left: 31px;
  background-color: #ccc;
}

.fa-solid.fa-sun {
  color: #ffeb3b;
}

.fa-solid.fa-moon {
  color: orange;
}

header .items .account {
  width: 40px;
  height: 40px;
  border: 2px solid var(--primary-text-color);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
}

header .items .account:hover {
  background: #ccc;
}

header .items .account i {
  font-size: 18px;
}

.HeaderComponent .navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
}

.HeaderComponent .navbar li a {
  color: var(--primary-text-color);
  transition: all 0.5s;
}

.HeaderComponent .navbar li a:hover {
  color: var(--primary-text-hover-color);
  border-bottom: 1px solid var(--primary-text-hover-color);
}

/* header section */
.header-section {
  height: 300px;
  background-image: url("../../Images/work2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-section .card {
  background-color: #febc59;
  max-width: 350px;
  width: 100%;
  height: 150px;
  border-radius: 16px;
  border: none;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 10px;
  text-align: center;
}

.header-section .card h1 {
  font-weight: 500;
}

/* product */
.product-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 50px 20px;
}

.product-card {
  position: relative;
  border: 1px solid var(--secondary-text-color);
  border-radius: 8px;
  width: 200px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.likeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-info {
  margin-top: 12px;

  text-align: center;
}

.product-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-text-color);
}

.product-price {
  color: #ff9900;
  font-size: 16px;
}

.product-description {
  font-size: 14px;
  color: var(--third-text-color);
  margin: 8px 0;
}

.learn-more-btn {
  background-color: #ffcc00;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.learn-more-btn:hover {
  background-color: #ff9900;
}

/* product detail */
.product-details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

/* caorusel */
.product-details .carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-details .main-image-container {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.product-details .main-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.product-details .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
}

.product-details .arrow.left {
  left: 10px;
}

.product-details .arrow.right {
  right: 10px;
}

.product-details .thumbnails {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.product-details .thumbnail {
  width: 80px;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.product-details .thumbnail.active {
  transform: scale(1.1);
  border: 2px solid #007bff;
}

.product-details .about {
  font-family: "Arial, sans-serif";
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.product-details .product-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-details .title-highlight {
  color: #ffd700;
}

.product-details .product-description {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.product-details .product-pricing {
  font-size: 18px;
  margin-bottom: 20px;
}

.product-details .old-price {
  text-decoration: line-through;
  color: #aaa;
  margin-right: 10px;
  opacity: 0.5;
}

.product-details .new-price {
  font-size: 24px;
  font-weight: bold;
  color: #005f73 !important;
}

.product-details .product-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.product-details .btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-details .btn-website {
  background-color: #fff;
  border: 2px solid #ffd700;
  color: #333;
  width: 48%;
}

.product-details .btn-website:hover {
  background-color: #ffd700;
  color: #fff;
}

.product-details .btn-add-to-cart {
  background-color: #fff;
  border: 2px solid #333;
  color: #333;
  width: 48%;
}

.product-details .btn-add-to-cart:hover {
  background-color: #333;
  color: #fff;
}

.product-details .btn-buy-now {
  background-color: #005f73;
  border: 2px solid #005f73;
  color: #fff;
  width: 48%;
}

.product-details .btn-buy-now:hover {
  background-color: #003d4a;
}

/* header sidebar  */
.list-group-item-action {
  cursor: pointer;
  transition: all 0.4s;
}

.list-group-item-action:hover {
  background: #dfdede;
}

.list-group-item-action.active {
  background: var(--primary-input-color);
  color: #fff;
}

.bi-heart-fill {
  cursor: pointer;
  transition: all 0.5s;
}

.bi-heart-fill:hover {
  fill: rgb(214, 5, 5);
}

/* MEDIA RESPONSIVE  */
@media screen and (max-width:995px) {
  .logotip {
    width: 60px;
    margin: 0;
    padding: 0;
  }

  header {
    display: flex;
    /* margin-bottom: 60px; */
  }

  header .items {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  header .items .select-language {
    width: 92px;
    margin: 0;
    padding: 0;
  }

  header .items .select-language .main-language {
    padding: 2px 14px;
  }

  .header .items .nav-bar {
    width: 22px;
  }

  header .items .select-language .dropdown {
    top: 30px;
  }

  .fa-chevron-down {
    margin-top: 3px;
    margin-bottom: 0;
  }

  div.navbar {
    display: flex;
    flex-direction: column;
  }

  .nav-bar .fa-bars {
    display: block;
  }

  .nav-bar .fa-remove {
    display: block;
  }

  .dark-mode {
    width: 50px;
    padding: 12px 0;
  }

  .toggle-knob {
    width: 22px;
    height: 22px;
  }

  .dark-mode.toggled .toggle-knob {
    left: 24px;
  }

  header .items .account {
    width: 30px;
    height: 30px;
  }

  header .items .account .fa-user {
    font-size: 14px;
  }

  .HeaderComponent .navbar-header {
    display: none;
  }

  .row-wrap {
    display: flex;
    flex-direction: row;
  }

  .side-bar {
    max-width: 70px;
  }

  .side-bar ul {
    min-width: 56px;
  }

  .side-bar ul a {
    min-width: 40px;
    padding-right: 10px;
  }

  .side-bar a span {
    display: none;
  }

  /* serach */
  header .search-input {
    display: none;
  }

  .search-responsive-input {
    display: flex;
    width: 240px;
  }
  .account-dark-mode .bi-cart4{
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width:520px) {
  .outlet-main{
    width: 70%;
  }
  .container_Sign_body{
   padding-top: 20px;
   padding-bottom: 40px;
  }
  .outlet-main table thead tr th{
    font-size: 10px;
  }
  .outlet-main table tbody tr td{
    font-size: 10px;
  }
  .outlet-main table tbody tr th{
    font-size: 10px;
  }
  .d-md-block .btn{
    font-size: 10px;
  }
  .card_SignUp_secondCard{
    margin: 0;
    margin-top: 10px;
  }
  .card_SignIn_secondCard{
    margin: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width:424px) {
  .favourites {
    max-width: 100%;
  }

  .favourites .card {
    width: 16rem !important;
  }

  .search-responsive-input {
    display: flex;
    width: 180px;
  }

  .search-respon-input {
    width: 32px;
    height: 28px;
  }

  .search-responsive-input input {
    width: 84%;
  }
  .search-responsive-input .fa-magnifying-glass {
    padding-right: 0;
    padding: 0;
    width: 20px;
  }

  .dark-mode {
    width: 40px;
    padding: 10px 0;
  }

  .toggle-knob {
    width: 18px;
    height: 18px;
  }

  .dark-mode.toggled .toggle-knob {
    left: 20px;
  }

  header .items .account {
    width: 24px;
    height: 24px;
  }

  header .items .account .fa-user {
    font-size: 14px;
  }
}

@media screen and (max-width:390px) {
  .favourites {
    max-width: 100%;
  }

  .favourites .card {
    width: 14rem !important;
  }
  .account-dark-mode .bi-cart4{
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width:360px) {
  .favourites {
    max-width: 100%;
  }

  .favourites .card {
    width: 13rem !important;
  }
}

@media screen and (max-width:342px) {
  .favourites {
    max-width: 100%;
  }

  .favourites .card {
    width: 11rem !important;
  }
}