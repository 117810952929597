@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");

.ProductCards{
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    align-items:center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    padding: 40px 0;
}
.ProductCard_wrap {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:4px;
  height: 320px;
  width: 240px;
}
.ProductCard_smallWrap {
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:4px;
  width: 160px;
  height: 160px;
}
.ProductCard_wrap1{
    background-image: url("../../Images/portfolio.jpg");
}
.ProductCard_wrap2{
    background-image: url("../../Images/company.jpg");
}
.ProductCard_wrap3{
    background-image: url("../../Images/restuarant.jpg");
}
.ProductCard_wrap4{
    background-image: url("../../Images/blog.jpg");
}
.ProductCard_smallWrap h2 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arimo", sans-serif;
  font-size: 24px;
  margin-top: 20px;
}
.ProductCard_smallWrap p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arimo", sans-serif;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: bold;
  font-style: italic;
}
.ProductCard_smallWrap .ProductCard_image {
  border-radius: 50%;
  background:#2169AC;
  color:#89CCFF;
  border: 3px solid #2169AC;
  cursor: pointer;
  transition: all 0.5s;
}
.ProductCard_smallWrap .ProductCard_image:hover {
  background:#89CCFF;
  color:#2169AC;
  border: 3px solid #89CCFF;
}