@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");

.profile{
    width: 100%;
    height: 100vh;
    background-color: white;
    border-radius: 4px;
    padding: 28px 36px;
}
.profile_information{
    display: flex;
    align-items: center;
    gap:20px;

}
.profile_information img{
    border:1px solid black;
    border-radius:50%;
}
.profile_information_text h3{
    font-size: 24px;
    font-family: "Arimo", sans-serif;
    font-weight: 500;
    line-height: 0.5;
    margin-top: 4px;
}
.profile_information_text span{
    font-family: "Arimo", sans-serif;
    margin-left: 4px;
    font-size: 14px;
}
table{
    border:1px solid black;
    margin-top: 60px;
}
table tr td svg {
    cursor: pointer;
}

@media screen and (max-width:1200px) {
    .profile table{
        display: inline-block;
        width: 70% !important;
    }
    .profile table thead,tbody tr th,td{
        width: 100% !important;
    }
}
@media screen and (max-width:766px) {
    .profile table{
        display: inline-block;
        width: 100% !important;
    }
}
@media screen and (max-width:464px) {
    .profile{
        padding: 10px;
    }
    .profile_information img{
        width: 48px;
        height: 48px;
    }
    .profile_information{
        gap: 10px;
    }
    .profile_information_text h3{
        font-size: 16px;
    }
    .profile_information_text span{
        font-size: 12px;
    }
    .profile table{
        display: inline-block;
        width: 200px;
    }
}
@media screen and (max-width:400px) {
    .profile table{
        display: inline-block;
        width: 100% !important;
    }
    .profile table thead,tbody tr th,td{
        width: 100% !important;
    }
    .profile table th,td{
        font-size: 12px;
    }
}