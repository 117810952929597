* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;800;900&display=swap);

body {
	font-family: 'Montserrat', sans-serif;
	background-color: #fff;
}

button, i {
	cursor: pointer;
}

input:focus {
	outline: none;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}