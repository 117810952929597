@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");

.settings{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 4px;
    padding: 28px 36px;
}
.settings_information{
    display: flex;
    align-items: center;
    gap:20px;

}
.settings_information img{
    border:1px solid black;
    border-radius:50%;
}
.settings_information_text h3{
    font-size: 24px;
    font-family: "Arimo", sans-serif;
    font-weight: 500;
    line-height: 0.5;
    margin-top: 4px;
}
.settings_information_text span{
    font-family: "Arimo", sans-serif;
    margin-left: 4px;
    font-size: 14px;
}
.settings_message_all{
    margin-top: 40px;
}
.setting_wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 4px;
}
.setting_wrap label{
    font-family: "Arimo", sans-serif;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}
#name{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 8px 16px;
    width: 50%;
    border:1px solid black;
    border-radius:20px;
}
#name h5{
    font-family: "Arimo", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
}
#name svg{
    cursor: pointer;
    transition: all 0.5s;
}
#name svg:hover{
    transform: scale(1.2);
    color:rgb(9, 197, 9);
}



@media screen and (max-width:464px) {
    .settings{
        padding: 10px;
    }
    .settings_information img{
        width: 48px;
        height: 48px;
    }
    .settings_information{
        gap: 10px;
    }
    .settings_information_text h3{
        font-size: 16px;
    }
    .settings_information_text span{
        font-size: 12px;
    }
}
@media screen and (max-width:930px) {
    #name{
        width: 100%;
    }
    .setting_wrap h5{
        font-size: 16px;
    }
}