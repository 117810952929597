.favourites{
    width: 100%;
    background-color: white;
    padding: 20px;
}
.favourites .card{
    position: relative;
}
.favourites .card-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.favourites .card svg{
    position: absolute;
    right: 10px;
    top: 10px;
    color: red;
    width: 24px;
    height: 24px;
}

@media screen and (max-width:374px) {
    .logotip{
        width: 44px !important;
        height: 44px;
    }
    .search-respon-input{
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
    }
}