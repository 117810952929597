.marginBottom {
    margin-bottom: 0px;
}

.row1 {
    padding-top: 30px;
    padding-bottom: 20px;
}

.row2 {
    margin-top: 30px;
}

.display {
    display: none;
}

.footer-col{
    padding-left: 40px;
}

.animationFooter {

    position: relative;
    animation-name: anim1;
    animation-duration: 1s;


}

.marginleft {
    position: relative;
    animation-name: anim2;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.footer-row1{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.footer-row1 .footer-col div{
    text-align: start;
}
.footer-col{
    max-width: 270px;
}
.footer-icon{
    cursor: pointer;
    transition: all 0.4s;
}
.footer-icon:hover{
    transform: scale(1.1);
}


@keyframes anim1 {
    0% {
        left: -10px;
        top: 0px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

@keyframes anim2 {

    from {
        left: 0px
    }

    to {
        left: 10px
    }
}
/* MEDIA  */
@media screen and (max-width:1200px) {
    .footer-row1{
        padding-bottom: 20px;
        display: flex;
        gap: 20px;
    }
    .footer-col{
        padding-left: 0;
    }
    .footer-row1 .footer-col div{
        width: 200px;
    }
    .footer-bottom{
        display: none;
    }
}
@media screen and (max-width:770px){
    .footer-row1 .footer-col div{
        width: 220px;
        text-align: justify;
        padding-left: 50px;
    }
}
@media screen and (max-width:454px) {
    .footer-row1{
        gap: 30px;
    }
    .footer-col{
        max-width: 200px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    .footer-row1 .footer-col div {
        width: 300px;
        padding-left: 0;
    }
}