.container_payment{
    margin-top: 100px ;
}
.mx-25{
    margin-left: 25%;
    margin-right: 25%;
}

.col-left{
    background-color: rgba(91, 12, 164, 0.723);
}

.img_cards{
    height: 40px;
    width: 60px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.cards{
    margin: auto;
    width: 80%;
    background-color: white ;
    border-radius: 5px;
}

.card-body-bottom{
    height: 140px;
}

@media screen and (max-width : 750px){
    .col-left{
        display: none;
    }
}