@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.HowSiteWork{
    background: #29211B;
}
.HowSiteWork_wrap{
    max-width: 1360px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.HowSiteWork_wrap h2{
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 40px;
}
.HowSiteWork--about p{
    font-family: "PT Serif", serif;
    font-weight: 700;
    font-size: 16px;
}